<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">
              {{ $store.state.language.lang[1] }}
            </router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{ $store.state.language.lang[112] }}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-12">
            <div class="m_body_title">
              <h2> {{ $store.state.language.lang[112] }}</h2>
            </div>
            <div class="main_themas">
              <div class="row m_f_obs">
                <div class="col-12 col-xl-5 ">
                  <div class="f_object">
                    <div>
                      <div class="ob_title">
                        {{ $store.state.heritage_id.title }}
                      </div>
                      <div class="ob_desc">
                        {{$store.state.heritage_id.parent_category}}
                      </div>
                    </div>
                    <div class="mg_img">
                      <img src="../assets/img/vectors.png" alt="">
                    </div>
                    <div class="f_m_oblog">
                      <div class="ob_loc">
                        {{$store.state.heritage_id.address}}
                      </div>
                      <div class="ob_loc">

                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-12 col-xl-7  ob_images">
                  <img :src="$store.state.heritage_id.image" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="ob_cards">
              <h3 class="ob_cards_title">
               {{$store.state.heritage_id.title}}
              </h3>
              <div class="ob_cards_description" v-html="$store.state.heritage_id.description">
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="ob_tables">
              <div class="ob_tables_child">
                <div class="ob_tables_title">{{ $store.state.language.lang[128] }}:</div>
                <div class="ob_tables_description">{{$store.state.heritage_id.parent_category}}</div>
              </div>
              <div class="ob_tables_child">
                <div class="ob_tables_title">{{ $store.state.language.lang[130] }}</div>
                <div class="ob_tables_description">{{ $store.state.heritage_id.ownership_type }}</div>
              </div>
              <div class="ob_tables_child">
                <div class="ob_tables_title">{{$store.state.language.lang[131]}}:</div>
                <div class="ob_tables_description">{{$store.state.heritage_id.condition}}</div>
              </div>
              <div class="ob_tables_child">
                <div class="ob_tables_title">{{ $store.state.language.lang[132] }}</div>
                <div class="ob_tables_description">{{$store.state.heritage_id.negative_effect}}</div>
              </div>
              <div class="ob_tables_child">
                <div class="ob_tables_title">{{$store.state.language.lang[134]}}</div>
                <div class="ob_tables_description">{{$store.state.heritage_id.required_actions}}</div>
              </div>
              <div class="ob_tables_child">
                <div class="ob_tables_title">{{$store.state.language.lang[135]}}</div>
                <div class="ob_tables_description">{{$store.state.heritage_id.technical_condition}}</div>
              </div>

            </div>
          </div>
          <div class="col-12">
            <div class="object_maps">
              <h3 class="ob_cards_title">
                {{$store.state.language.lang[177]}}
              </h3>

              <div class="maps" >
                <l-map :options="{scrollWheelZoom: false}" style="height: 482px;width: 100%;border-radius: 5px" :zoom="zoom"
                       :center="[center.lat,center.lng]">
                  <l-tile-layer :url="url"></l-tile-layer>

                  <l-marker :draggable="false"
                            :lat-lng="[center.lat,center.lng]">
                    <l-icon
                        :icon-size="[30,35]"
                        :icon-url="img_icon"
                        :shadow-url="require('@/assets/marker-shadow.png')"
                    />
                  </l-marker>

                </l-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Header/Footer";

import {LMap, LTileLayer, LMarker, LIcon} from 'vue2-leaflet';
import icon from "@/assets/img/maps_icon.png";
import axios from "axios";
import router from "@/router";
export default {
  components: {
    Footer,
    Navbar,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  data() {
    return {
      img_icon: icon,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 11,
      isselcts: false,
      isselcts1: false,
      year: '2022',
      isActive_m: false,
      selected: '',
      selected1: 'Dekabr',
      month: '',
      options: [
        {value: null, text: 'Please select some item'},
        {value: 'a', text: 'This is First option'},
        {value: 'b', text: 'Default Selected Option'},
        {value: 'c', text: 'This is another option'},
        {value: 'd', text: 'This one is disabled', disabled: true}
      ],
      options1: [
        {value: null, text: 'Please select some item'},
        {value: 'a', text: 'This is First option'},
        {value: 'b', text: 'Default Selected Option'},
        {value: 'c', text: 'This is another option'},
        {value: 'd', text: 'This one is disabled', disabled: true}
      ],
      center:{
        lat:'',
        lng:''
      }
    }
  },
  mounted() {
    axios.get('/api/heritage/'+router.currentRoute.params.id+'/',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
    })
        .then(res=>{
          this.$store.state.heritage_id=res.data
          this.center={
            "lat":res.data.latitude,
            "lng":res.data.longitude
          }
        })
  },
  methods: {
    openSelects() {
      this.isselcts = !this.isselcts
      this.isselcts1 = false

    },
    openSelects1() {
      this.isselcts1 = !this.isselcts1
      this.isselcts = false

    },
    filter() {

      this.$store.dispatch('newsMfiltr', {
        "year": this.year,
        "month": this.month
      })
    }
  }
}
</script>
